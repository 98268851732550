import React from 'react'
import Helmet from 'react-helmet'

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <Helmet>
        {/* Start cookieyes banner */}
        <script
          key="cookieyes"
          id="cookieyes"
          type="text/javascript"
          src="https://cdn-cookieyes.com/client_data/2a76f6d0d3a27f892bc573d2/script.js"
        ></script>
        ,{/* End cookieyes banner */}
      </Helmet>
      {element}
    </>
  )
}
